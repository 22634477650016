import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { StoryData } from 'storyblok-js-client';
import AuthorPageComponent from '../sb-components/author-page/author-page.component';
import Layout from '../sb-components/layout';
import { CommonData, GlobalData } from '../common-types';
import StoryblokService from '../utils/storyblok-service';
import BlogContext from '../sb-components/blogContext';
import Page from '../sb-components/page';
import { getEnvironment } from '../utils/helpers';

interface State {
  pageData?: CommonData;
  globalData: GlobalData;
  path: string;
  blogs?: any;
  authors?: any;
  authorContent?: any;
}

interface Props
  extends PageProps<{
    pageData: StoryData<string>;
    globalData: StoryData<string>;
    blogs?: any;
    authors?: any;
    authorContent?: any;
  }> {}

class AuthorPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const authorsArray = props.data.authors.edges.map((item: any) => ({
      ...item.node,
    }));

    const authorsDict = Object.assign(
      {},
      ...authorsArray.map((item: any) => ({
        [item.uuid]: { ...item, content: JSON.parse(item.content) },
      }))
    );

    const authorContent = authorsArray.find(
      (author: any) => props.path === `/${author?.full_slug}/`
    );

    if (authorContent?.content) {
      authorContent.content = JSON.parse(authorContent.content);
    }

    this.state = {
      globalData: JSON.parse(props.data.globalData.content),
      path: props.path,
      blogs: props.data.blogs.edges.map((item: any) => ({
        ...item.node,
        content: JSON.parse(item.node.content),
      })),
      authors: authorsDict,
      authorContent,
    };
  }

  async componentDidMount() {
    const { authorContent } = this.state;
    try {
      const response = await StoryblokService.get(
        `cdn/stories/${this.state.path}`,
        {
          version: getEnvironment() !== 'PROD' ? 'draft' : '',
        }
      );

      const { content } = response.data.story;
      this.setState((prevState) => ({
        ...prevState,
        pageData: { ...content },
        authorContent: {
          ...authorContent,
          content: {
            ...authorContent.content,
            author: content.author,
          },
        },
      }));

      setTimeout(() => StoryblokService.initEditor(this), 200);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  render() {
    const pageContext = this.props.pageContext as any;
    const slug = pageContext?.story?.full_slug;
    const contentString = pageContext?.story?.content;
    const content = contentString && JSON.parse(contentString);
    const seoMeta = content?.seoMeta;

    const { blogs, authors, authorContent, globalData, path, pageData } =
      this.state;
    const { header, footer, cookiesConsent } = globalData;

    return (
      <BlogContext.Provider
        value={{
          blogs,
          authors,
          author: authorContent?.uuid,
        }}
      >
        <Layout
          className="page page-author"
          header={header}
          footer={footer}
          showBlock
          cookiesConsent={cookiesConsent}
          full_slug={path.replace(/(\/)/, '')}
          path={`/${slug}`}
        >
          <AuthorPageComponent blok={authorContent} />
          <Page
            blok={{
              ...pageData,
              seoMeta,
            }}
          />
        </Layout>
      </BlogContext.Provider>
    );
  }
}

export default AuthorPage;

// pageData: storyblokEntry(slug: { eq: "authors" }) {
//   name
//   content
//   full_slug
//   uuid
// }

export const query = graphql`
  {
    globalData: storyblokEntry(full_slug: { eq: "global" }) {
      name
      content
      full_slug
      uuid
    }
    blogs: allStoryblokEntry(filter: { field_component: { eq: "post" } }) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          content
          tag_list
          uuid
        }
      }
    }
    authors: allStoryblokEntry(filter: { field_component: { eq: "author" } }) {
      edges {
        node {
          id
          full_slug
          uuid
          content
          name
        }
      }
    }
  }
`;
