import React, { useEffect, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { AuthorItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import Linkedin from '../../assets/images/linkedin.svg';
import Link from '../../components/common/link/link.component';
import Left from '../../assets/images/Left.svg';

import './author-page.component.scss';

// eslint-disable-next-line import/order
import { render } from 'storyblok-rich-text-react-renderer-ts';

const SbAuthorComponent: ReactDynamicComponent<AuthorItemStoryblok> = ({
  blok,
}) => {
  const [blockContent, setBlockContent] = useState<any>({});
  useEffect(() => {
    // @ts-ignore
    setBlockContent(blok && blok.content.author[0]);
  }, [blok]);

  const {
    name,
    description2,
    additionalDescription,
    image,
    jobPosition,
    linkedInUrl,
    backgroundImage,
  } = blockContent;

  return (
    <div {...storyblokEditable(blok)} className="author-page">
      <div
        onClick={() => window.history.back()}
        className="author-page__back-link-container Poppins-16-24-Bold"
      >
        <div className="author-page__back-link">
          <Left />
          Back
        </div>
      </div>
      <div className="author-page__content">
        <div className="author-page__background">
          {backgroundImage && backgroundImage[0] && (
            <AdaptiveImage
              src={backgroundImage[0].image.filename}
              mobilesrc={backgroundImage[0].mobileImage.filename}
              alt={backgroundImage[0].image.alt}
              processing={backgroundImage[0].processing}
              width="100%"
            />
          )}
        </div>
        <div className="author-page__photo">
          {image && image[0] && (
            <AdaptiveImage
              src={image[0].image.filename}
              alt={image[0].image.alt}
              processing={image[0].processing}
            />
          )}
        </div>
        <div className="author-page__info-wrapper">
          <div className="author-page__info">
            <div className="author-page__name">
              <div>{name}</div>
              <div className="author-page__author-linkedin">
                <Link link={linkedInUrl}>
                  <Linkedin />
                </Link>
              </div>
            </div>
            <div className="author-page__job-position">{jobPosition}</div>
          </div>
        </div>
      </div>
      <div className="author-page__info-description">
        <div className="author-page__description">{render(description2)}</div>
        <div className="author-page__additional">
          {render(additionalDescription)}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default SbAuthorComponent;
